<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="head">
      <div class="title">{{ $t("language") }}</div>
    </div>
    <div class="ScrollBox">
      <div class="newBox">
        <van-radio-group v-model="radio" ref="font">
          <!-- :icon="`./static/icon/en-US.png`" -->
          <van-cell
                  class="english"
                  title="English"
                  @click="changeLanguage('en-US')"
          >
            <van-radio name="1" class="radio" />
          </van-cell>
          <!-- :icon="`./static/icon/vi-VN.png`" -->
          <van-cell
                  class="tieng"
                  title="Tiếng Việt"
                  @click="changeLanguage('vn')"
          >
            <van-radio name="2" class="radio" />
          </van-cell>
          <!-- :icon="`./static/icon/zh-CN.png`" -->
          <van-cell
            class="china"
            title="简体中文"
            @click="changeLanguage('zh-CN')"
          >
            <van-radio name="3" class="radio" />
          </van-cell>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Language",
    components: {},
    props: {},
    data() {
      return {
        radio: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      this.selectLan();
    },
    mounted() {},
    activated() {},
    destroyed() {},
    methods: {
      changeLanguage(lan) {
        this.$SetLanguage(lan);
        this.$router.go(-1);
        this.selectLan();
      },
      selectLan() {
        let a = localStorage.getItem("Language");
        switch (a) {
          case "en":
            this.radio = "1";
            // console.log("11111");
            break;
          case "vn":
            this.radio = "2";
            break;
          case "cn":
            this.radio = "3";
            break;
          case "tc":
            this.radio = "4";
            break;
          case "fr":
            this.radio = "5";
            break;
        }
      },
    },
  };
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #0F1A29;
  }

  .van-nav-bar {
    background-color: #0F1A29;
  }
  .van-cell__left-icon {
    height: auto;
    line-height: 1;
  }
  .van-cell__title {
    color: #000;
  }

  .head {
    width: 100%;
    margin-top: 20px;
  }
  .title {
    background: #5652FD;
    color: #fff;
    height: 35px;
    width: 145px;
    text-align: center;
    margin: auto;
    line-height: 35px;
    border-radius: 5px;
    font-size: 21px;
    font-weight: 600;
  }
  .PageBox >>> .van-cell {
    position: inherit;
  }
  .ScrollBox {
    padding: 25px;
  }
  .newBox {
    border: #dbdbdbdb 1px solid;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 2px #dbdbdbdb;
  }
  .van-cell {
    border-bottom: 0.5px solid #e7e7e7;
    font-size: 16px;
  }
  .van-cell:nth-last-of-type(1) {
    border: none;
  }
  .radio {
    margin-left: 80%;
  }
  .PageBox >>> .van-icon-success {
    border: 2px solid #dbdbdbdb;
  }
  .ic {
    background: #4e51bf;
    width: 200px;
  }
  .PageBox >>> .van-radio__icon--checked .van-icon {
    background-image: url("../../public/static/icon2/12.png");
    background-color: #fff;
    color: rgba(0, 0, 0, 0);
    border: none;
    background-size: 20px;
    background-repeat: no-repeat;
  }
  .ScrollBox >>> .van-cell {
    padding: 14px 16px;
  }
</style>
